export default {
  users: {
    usersManagement: 'إدارة المساهمين',
    usersList: 'قائمة المساهمين',
    user: 'المساهم',
    accessLevels: 'صلاحيات الوصول',
    email: 'البريد الإلكتروني',
    createdDate: 'تاريخ الإنشاء',
    status: 'الحالة',
    actions: 'الإجراءات',
    addNewUser: 'إضافة مساهم جديد',
    totalPersonalisedExperience: 'تجربة شخصية متكاملة لملفك الشخصي، أضف معلومات شركتك لتحصل على أعلى قيمة من راس مال',
    type: 'النوع',
    Individual: 'فرد',
    Institution: 'مؤسسة',
    legalName: 'الاسم القانوني',
    typeUserOrFounderLegalName: 'اكتب اسم المساهم او الاسم القانوني للمؤسس',
    typeEmailOfUser: 'اكتب البريد الالكتروني example@example.com',
    position: 'المسمى الوظيفي',
    Founder: 'مؤسس',
    'Co-Founder': 'شريك مؤسس',
    'Legal counsel': 'مستشار قانوني',
    Investor: 'مستثمر',
    Employee: 'موظف',
    Advisor: 'مستشار',
    'Third-party': 'طرف ثالث',
    addNewUsers: 'إضافة مساهمين جدد',
    editUser: 'تعديل المساهم',
    editUsers: 'تعديل المساهمين',
    back: 'رجوع',
    submit: 'تقديم',
    cancel: 'إلغاء',
    userDetails: 'تفاصيل المساهم',
    close: 'إغلاق',
    active: 'نشط',
    disabled: 'معطل',
    invitationSent: 'تم إرسال الدعوة',
    resend: 'إعادة الإرسال',
    shareAccess: 'مشاركة الوصول',
    isAdmin: 'مدير النظام',
    canTradeShares: 'يمكن التداول بالأسهم',
    company: 'شركة',
  },

  roles: {
    usersManagement: 'إدارة المساهمين',
    accessLevels: 'صلاحيات الوصول',
    rolesPermissions: 'صلاحيات الوصول',
    name: 'الاسم',
    description: 'الوصف',
    actions: 'الإجراءات',
    addNewAccessLevel: 'إضافة صلاحية وصول جديدة',
    editRolesAndPermissions: 'تعديل صلاحيات الوصول',
    accessLevelDetails: 'تفاصيل صلاحية الوصول',
    permissions: 'الصلاحيات',
    createdDate: 'تاريخ الإنشاء',
    close: 'إغلاق',
    addNewRolePermissions: 'إضافة صلاحيات جديدة',
    totalPersonalisedExperience: 'تجربة شخصية متكاملة لملفك الشخصي، أضف معلومات شركتك لتحصل على أعلى قيمة من راس مال',
    titleOfNewRole: 'اسم صلاحية الوصول الجديد',
    suggestedNames: 'الأسماء المقترحة:',
    giveThisRoleName: 'امنح هذه الصلاحيه اسمًا',
    descriptionPlaceholder: 'وصف صلاحية الوصول',
    permissionSetup: 'إعداد الصلاحيات',
    selectAll: 'اختر الكل',
    manageUsers: 'إدارة المساهمين',
    manageRoles: 'إدارة الصلاحيات',
    captable: 'جدول الرسملة',
    governance: 'الحوكمة',
    equityList: 'قائمة الأسهم',
    optionList: 'قائمة خيارات الأسهم',
    convertibleInstrumentsList: 'قائمة السندات القابلة للتحويل',
    dashboard: 'لوحة التحكم',
    dataroom: 'غرفة البيانات',
    'Data room Main': 'غرفة البيانات الرئيسية',
    'Data room Shareable': 'غرفة البيانات المشتركة',
    userDashboard: 'لوحة المستخدم',
    runway: 'المدرج المالي',
    financialAnalysis: 'تحليل مالي',
    loanRepayments: 'جداول السداد',
    scenarioModeling: 'النمذجة',
    'Scenario Modeling Round': 'نمذجة الجولة الاستثمارية',
    'Scenario Modeling Exit': 'نمذجة التخارج',
    projectCashflow: 'التدفقات النقدية للمشروع',
    'Add Equity': 'إضافة أسهم',
    'Edit Equity': 'تعديل الأسهم',
    'Delete Equity': 'حذف أسهم',
    'Add Option': 'إضافة خيارات الأسهم',
    'Edit Option': 'تعديل خيارات الأسهم',
    'Delete Option': 'حذف خيارات الأسهم',
    addWarrant: 'إضافة مذكرة',
    editWarrant: 'تعديل المذكرة',
    deleteWarrant: 'حذف المذكرة',
    'Create a new Convertible Instruments': 'إنشاء سندات جديدة قابلة للتحويل',
    'Delete Convertible Instruments': 'حذف السندات القابلة للتحويل',
    financialTools: 'أدوات التحليل المالي',
    'Add Vesting': 'إضافة استحقاق الملكية',
    'Edit Vesting': 'تعديل الاستحقاق',
    'Delete Vesting': 'حذف الاستحقاق',
    vestingList: 'قائمة الاستحقاق',
    a: 'السجلات المشتركة',
    'Data room Shared Logs': 'سجلات المشاركة بغرفة البيانات',
    'Data room Download': 'تحميل غرفة البيانات',
    activityLogs: 'سجل النشاط',
    minutesOfTheMeeting: 'محاضر الاجتماعات',
    momTemplates: 'نماذج محاضر الاجتماعات',
    'Data room Management': 'إدارة غرفة البيانات',
    'Data room Share Button': 'زر مشاركة غرفة البيانات',
    'Data room Analytics': 'تحليلات غرفة البيانات',
    captableOverview: 'نظرة عامة على جدول الرسملة',
    valuation: 'التقييم',
    back: 'رجوع',
    submit: 'تقديم',
    cancel: 'إلغاء',
    Employees: 'الموظفين',
    Investors: 'المستثمرين',
    Accountant: 'المحاسب',
    'Co-founders': 'الشركاء المؤسسين',
    'Data room Admin': 'مسؤول غرفة البيانات',
  },

  activity: {
    activityLogs: 'سجل العمليات',
    action: 'الإجراء',
    user: 'المستخدم',
    createdAt: 'تاريخ الانشاء',
    ok: 'تأكيد',
    reset: 'إعادة تعيين',
  },
};
